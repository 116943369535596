import { InjectionToken } from '@angular/core';
import {
  createAction,
  ActionReducer,
  ActionReducerMap,
  INIT,
  MetaReducer,
} from '@ngrx/store';

import { environment } from '../../environments/environment';
import * as fromEbook from '../ebook/store/ebook.reducer';
import * as fromPortfolio from '../handwriting/portfolio/store/portfolio.reducer';
import * as fromSpelling from '../spelling/store/spelling.reducer';
import * as fromTopicActivity from '../topic-activity/store/topic-activity.reducer';
import * as fromViewer from '../viewer/store/viewer.reducer';

export interface State {
  [fromEbook.ebookStateFeatureKey]: fromEbook.EbookState;
  [fromPortfolio.handwritingPortfolioStateFeatureKey]: fromPortfolio.HandwritingPortfolioState;
  [fromSpelling.spellingStateFeatureKey]: fromSpelling.SpellingState;
  [fromTopicActivity.topicActivityStateFeatureKey]: fromTopicActivity.TopicActivityState;
  [fromViewer.viewerStateFeatureKey]: fromViewer.ViewerState;
}

export const APP_REDUCERS = new InjectionToken<ActionReducerMap<State>>('App reducers token', {
  factory: () => ({
    [fromEbook.ebookStateFeatureKey]: fromEbook.reducer,
    [fromPortfolio.handwritingPortfolioStateFeatureKey]: fromPortfolio.reducer,
    [fromSpelling.spellingStateFeatureKey]: fromSpelling.reducer,
    [fromTopicActivity.topicActivityStateFeatureKey]: fromTopicActivity.reducer,
    [fromViewer.viewerStateFeatureKey]: fromViewer.reducer,
  }),
});

export const resetStore = createAction('[App] Reset state');

export function reset(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action && action.type === resetStore.type) {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [reset] : [reset];
