<div id="resourceAcessDenied" class="d-flex flex-column justify-content-start align-items-start w-100 h-100 bg-danger">
  <header class="text-white mx-auto">
    <h1><i class="fa fa-ban" aria-hidden="true"></i> Resource Access Denied</h1>
  </header>
  <main class="text-white mx-auto my-5">
    <div>
      <p>You do not have access to this resource.</p>
      <p>This may have occurred after having logged out of another window or tab, or your session has timed out. Please close this window or tab, and log in to ZB Portal again.</p>
    </div>
  </main>
  <footer class="mx-auto my-5">
    <button type="button" tabindex="0" class="btn btn-lg btn-secondary" (click)="close()">Close Window</button>
  </footer>
</div>