import { Component } from '@angular/core';

import { BootstrapService } from '@core/bootstrap.service';
import { UIHelpers } from '@shared/uihelpers';

@Component({
  selector: 'zbdr-resource-access-denied',
  templateUrl: './resource-access-denied.component.html',
  styleUrls: ['./resource-access-denied.component.scss'],
})
export class ResourceAccessDeniedComponent {
  constructor(private bootstrap: BootstrapService) {
  }

  close(): void {
    UIHelpers.closeWindowWithConfirmation(this.bootstrap.isStudent);
  }
}
