import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EceComponent } from './ece/ece.component';
import { ResourceAccessDeniedComponent } from './resource-access-denied/resource-access-denied.component';
import { ResourceNotFoundComponent } from './resource-not-found/resource-not-found.component';
import { RootComponent } from './root/root.component';

const routes: Routes = [
  { path: '', component: RootComponent },
  {
    path: 'superkids',
    component: RootComponent,
    loadChildren: () => import('./superkids/superkids.module').then(m => m.SuperkidsModule)
  },
  {
    path: 'handwriting',
    component: RootComponent,
    loadChildren: () => import('./handwriting/handwriting.module').then(m => m.HandwritingModule)
  },
  {
    path: 'spelling',
    component: RootComponent,
    loadChildren: () => import('./spelling/spelling.module').then(m => m.SpellingModule)
  },
  {
    path: 'ece',
    component: EceComponent,
    loadChildren: () => import('./ece/ece.module').then(m => m.EceModule),
  },
  {
    path: 'gum',
    component: RootComponent,
    loadChildren: () => import('./gum/gum.module').then(m => m.GumModule),
  },
  {
    path: 'i-read-to-write',
    component: RootComponent,
    loadChildren: () => import('./irtw/irtw.module').then(m => m.IrtwModule),
  },
  {
    path: 'jump-into-writing',
    component: RootComponent,
    loadChildren: () => import('./jitw/jitw.module').then(m => m.JitwModule),
  },
  {
    path: 'kickstart',
    component: RootComponent,
    loadChildren: () => import('./kickstart/kickstart.module').then(m => m.KickstartModule),
  },
  {
    path: 'mindscapes',
    component: RootComponent,
    loadChildren: () => import('./mindscapes/mindscapes.module').then(m => m.MindscapesModule),
  },
  {
    path: 'word-heroes',
    component: RootComponent,
    loadChildren: () => import('./wh/wh.module').then(m => m.WhModule),
  },
  {
    path: 'word-wisdom',
    component: RootComponent,
    loadChildren: () => import('./ww/ww.module').then(m => m.WwModule),
  },
  { path: 'access-denied', component: ResourceAccessDeniedComponent },
  { path: 'ResourceNotFound', component: ResourceNotFoundComponent },
  {
    path: '**',
    redirectTo: 'ResourceNotFound'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
