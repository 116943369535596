import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { BootstrapService } from '@core/bootstrap.service';
import { ProductService } from '@core/product.service';
import { SfxService } from '@core/sfx.service';
import { ApiResponse } from '@models/api-response';
import { BootstrapConfiguration, IBootstrapConfiguration } from '@models/bootstrap-config';
import { IProductLine } from '@models/product-line';
import { ITopic } from '@models/topic';
import { Products } from '@shared/products.enum';
import { UIHelpers } from '@shared/uihelpers';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, mergeMap, skipWhile, take, takeUntil } from 'rxjs/operators';
import { TopicActivityService } from './topic-activity.service';

@Component({
  selector: 'zbdr-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnDestroy, OnInit {
  config: IBootstrapConfiguration = null;
  unableToLaunch = false;
  productLine: IProductLine = null;
  ngDestroyed$ = new Subject<void>();

  private tokenCheckedSubscription: Subscription;

  constructor(
    private bootstrapService: BootstrapService,
    @Inject(DOCUMENT) private document: Document,
    private productService: ProductService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private sfx: SfxService,
    private titleService: Title,
    private topicActivityService: TopicActivityService,
  ) {
    if (this.bootstrapService.isLocalUrl) {
      console.log(`Root:${new Date()}`);
    }
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        takeUntil(this.ngDestroyed$),
        mergeMap((qp: Params) => {
          this.config = new BootstrapConfiguration(qp);
          return this.initializeBootstrapService();
        }),
        skipWhile(initialized => initialized === null),
        mergeMap((bootstrapSuccess: boolean) => {
          let result$: Observable<ApiResponse<IProductLine[]>>;
          if (bootstrapSuccess) {
            this.sfx.prepareProductAudioMetadataCollection(this.bootstrapService.product, this.bootstrapService.grade);
            // Apply the product name as the body class, and removes any other product name from the body class, which
            // may be because we're running tests.
            Object.keys(Products)
              .filter(key => isNaN(Number(key)))
              .forEach((name) => {
                if (Products[this.bootstrapService.product] === name) {
                  this.renderer.addClass(this.document.body, Products[this.bootstrapService.product]);
                } else {
                  this.renderer.removeClass(this.document.body, name);
                }
              });

            if (!this.bootstrapService.classroomId) {
              result$ = of(null);
            } else {
              result$ = this.productService.getProducts();
            }
          } else {
            result$ = of(null);
            UIHelpers.growlError('The appropriate activity could not be determined. Launch is stopped.');
          }
          this.routeWhenBootstrapInitialized(bootstrapSuccess);

          return result$;
        }),
        skipWhile(res => res === null),
        mergeMap((res: ApiResponse<IProductLine[]>) => {
          if (res) {
            const [productComponent, productLine] = this.productService.getProductComponent(
              res.response,
              this.bootstrapService.componentId);
            if (productLine && productComponent) {
              this.productLine = productLine;
            }
          }
          return this.topicActivityService.getTopicByTopicKey(
            this.bootstrapService.classroomId,
            this.bootstrapService.activity,
            this.bootstrapService.getProductType());
        }),
        catchError((err) => {
          console.error(err);
          return of(null);
        }),
      )
      .subscribe((res: ApiResponse<ITopic[]>) => {
        if (res) {
          if (res.response) {
            const topic = res.response[0];
            if (topic) {
              this.titleService.setTitle(`${this.productLine?.name} | ${topic.unitName} | ${topic.topicName} | My ZB Portal`);
            }
          }
        }
      });

    this.tokenCheckedSubscription = this.bootstrapService.hasToken
      .pipe(skipWhile(hasToken => hasToken === null))
      .subscribe((hasToken) => {
        if (!hasToken && this.bootstrapService.needsToken) {
          this.router.navigate(['access-denied'], { relativeTo: this.route });
          this.tokenCheckedSubscription.unsubscribe();
        }
      });
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  private initializeBootstrapService() {
    return this.bootstrapService.isInitialized
      .pipe(
        take(1),
        mergeMap((initialized) => {
          if (initialized === null) {
            return this.bootstrapService.initialize(this.config);
          }
          if (this.bootstrapService.isLocalUrl) {
            console.log('App is already bootstrapped.');
          }
          return of(true);
        })
      );
  }

  private routeWhenBootstrapInitialized(bootstrapSuccess: boolean) {
    if (!bootstrapSuccess) {
      this.unableToLaunch = true;
      this.router.navigate(['ResourceNotFound'], { relativeTo: this.route });
    } else if (this.bootstrapService.product === Products.handwriting) {
      this.routeHandwriting();
    } else if (this.bootstrapService.product) {
      this.routeWithZbPortalPathParameters();
    } else {
      console.error('product not identified');
      this.router.navigate(['ResourceNotFound'], { relativeTo: this.route });
    }
  }

  /**
   * Maps the product launch context to the relevant handwriting route.
   */
  private routeHandwriting(): void {
    const componentId = this.config.componentId.toLocaleLowerCase();
    if (componentId.slice(-2) === 'wm') {
      this.bootstrapService.activity = 'worksheet-maker';
      this.router.navigate(['handwriting', this.bootstrapService.activity], { skipLocationChange: true });
    } else if (componentId.slice(-3) === 'mwz' && this.bootstrapService.activity) {
      this.router.navigate(['handwriting', 'matching-with-zaney'], {
        queryParamsHandling: 'merge',
        skipLocationChange: true,
      });
    } else if (this.bootstrapService.activity) {
      this.routeWithZbPortalPathParameters();
    } else {
      this.router.navigate(['ResourceNotFound'], { relativeTo: this.route });
    }
  }

  /**
   * Maps path parameters required to launch product applications in different contexts.
   *
   * ZB Portal legacy product launcher creates a virtual path e.g. /product/:pid/ with
   * optional /:cid and /:cid/:aid arguments depending on the launch context having a
   * classroom or classroom and assignment identifier.
   */
  private routeWithZbPortalPathParameters(): void {
    const activity = this.bootstrapService.activityProduct;
    const routePath: string[] = [];

    if (!activity) {
      this.router.navigate(['ResourceNotFound'], { relativeTo: this.route });
    }

    if (activity) {
      // Adds the product (spelling, gum) / sub-product (spelling_practice, spelling_concentration).
      routePath.push(activity.product);

      if (activity.subProduct) {
        const subPath: string[] = activity.subProduct.split('/');

        routePath.push(...subPath);
      }
    }

    this.router.navigate(routePath, { queryParamsHandling: 'merge', skipLocationChange: true });
  }
}
