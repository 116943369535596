import { CompanyCodeEnum } from '@shared/enums/company-code-enum';
import { Helpers } from '@shared/helpers';
import { Products } from '@shared/products.enum';

import { ActivityProduct } from './activity-product';

export interface IBootstrapConfiguration {
  /**
   * Unique magic string to identify the product application.
   *
   * Specification: [SERIES][EDITION_YEAR][EDITION_SUFFIX]_[GRADEORVARIANT][GRADE_SUFFIX]_[COMPONENT_SUFFIX]
   *
   *   - series: Short code that refers to a Zaner-Bloser program such as Spelling (spcn) or La Escritura (laesc).
   *   - edition year: YYYY
   *   - edition suffix: null, TX (Texas) or N (National).
   *   - gradeorvariant:
   *      - grade: A grade designation in the pattern of g + 0-9pk+
   *      - variant: A non-grade variant designation such as "enac" or "abc" and this must not begin with g.
   *   - grade suffix: code that refers to a grade designation modifier such as C or M or IND. Non-grade variants must
   *     not implement suffixes.
   *   - component suffix: unique short code for the component such as WM (Worksheet Maker) or SQ (Spelling Quest).
   */
  componentId: string;
  /**
   * An optional globally-unique identifier for student work when launching in a Quest-context.
   */
  activityId: string;
  /**
   * The globally-unique identifier for the classroom.
   */
  classroomId: string;
  /**
   * A magic string to identify the activity, which is either an enum or a topic / assessment key.
   */
  activity: string;
  /**
   * The product line without the edition year or edition suffix.
   */
  product: Products;
  /**
   * The product type (for TX)
   */
  productType: string;
  /**
   * The representation of product and activity as product lines can have the same activity.
   */
  activityProduct: ActivityProduct;

  // Various product-related.

  /**
   * "Unique" viewer asset identifier magic string.
   *
   * Uniqueness is not guaranteed in Handwriting or Spelling due to content errors. Uniqueness
   * also includes the asset edition and language.
   */
  assetId: string;
  /**
   * Asset viewer language.
   *
   * One of en, es (legacy JSON) or English, Spanish (API).
   */
  language: string;

  topicKey: string;
  companyCode: CompanyCodeEnum;
  // Legacy.
  unit: string;
  level: string;
  grade: string;

  skipIntro: boolean;
}

export class BootstrapConfiguration implements IBootstrapConfiguration {
  componentId: string = null;
  activityId: string = null;
  classroomId: string = null;
  activity: string = null;
  unit: string = null;
  level: string = null;
  grade: string = null;
  assetId: string = null;
  language: string = 'en';
  skipIntro: boolean = false;
  product: Products = null;
  productType: string = null;
  activityProduct: ActivityProduct = null;
  topicKey: string = null;
  companyCode: CompanyCodeEnum = CompanyCodeEnum.ZB;

  constructor(values: { [key: string]: any }) {
    Object.keys(values).forEach((key: string) => {
      if (Object.prototype.hasOwnProperty.call(values, key) && values[key]) {
        if (key === 'skipIntro') {
          this.skipIntro = !!values[key] || false;
        } else {
          this[key] = values[key];
        }
      }
    });

    if (this.componentId) {
      this.setProductFromComponentId();
      this.companyCode = this.isHeg() ? CompanyCodeEnum.HEG : CompanyCodeEnum.ZB;
    }

    if (this.activity) {
      this.setActivityProduct();
    }
  }

  private setProductFromComponentId(): void {
    const componentId = this.componentId ? this.componentId.toLocaleLowerCase() : '';
    const matches = componentId.match(Helpers.componentIdProductLinePattern);
    if (matches && matches.length > 2) {
      if (matches[1] === 'spcn') {
        this.product = Products.spelling;
      } else if (matches[1] === 'hw' || matches[1] === 'laesc' || matches[1] === 'chw') {
        this.product = Products.handwriting;
      } else if (matches[1] === 'sk' || matches[1] === 'hea' || matches[1] === 'fsk') {
        this.product = Products.superkids;
      } else if (matches[1] === 'kns' || matches[1] === 'kenc') {
        this.product = Products.kick;
      } else if (Products[matches[1]]) {
        this.product = Products[matches[1]];
      }

      const gradeMatches = componentId.match(Helpers.variantPattern);
      if (gradeMatches && gradeMatches.length > 1) {
        this.grade = gradeMatches[1].substring(0, 1) === 'g' ? gradeMatches[1].substring(1) : null;
      }
    }
  }

  private setActivityProduct(): void {
    if (this.activity === 'viewer') {
      this.activityProduct = { product: this.product, subProduct: 'viewer' };
    } else if (this.activity === 'ebook') {
      this.activityProduct = { product: this.product, subProduct: 'ebook' };
    } else if (this.activity.startsWith('DR_')) {
      this.activityProduct = { product: this.product, subProduct: 'whiteboard' };
    } else if (this.activity === 'superkids-own-webpages') {
      this.activityProduct = { product: this.product, subProduct: 'superkids-own-webpages' };
    } else if (this.product === Products.kick && this.activity === 'number-practice') {
      this.activityProduct = { product: this.product, subProduct: 'number-practice' };
    } else if (this.product === Products.ece && this.activity === 'print-resources') {
      this.activityProduct = { product: this.product, subProduct: 'print-resources' };
    } else {
      // Maps the a magic string code in the topicKey, common to the content spreadsheet, back end, front end
      // and eventual new ZB Portal back end, to an activity sub-product. This maintains flexibility so that
      // content editors can use a magic string in spreadsheets while the back end and front end can opt-out
      // later for the guid, topicId.

      const matches: RegExpMatchArray = this.activity.match(Helpers.topicKeyPattern);
      if (matches && matches.length > 2) {
        // The first element in the match array contains the entire regex match, while the second element
        // contains the captured text (the match within parantheses).
        const prefix = matches[1];

        this.unit = matches[3];

        switch (prefix) {
        case 'Wr':
          this.activityProduct = { product: this.product, subProduct: 'topic-activity/writing' };
          break;
        case 'Ex':
        case 'Pa':
        case 'Pr':
          // Extra Pattern Practice, Pattern Activity and Practice run via the same mechanism.
          this.activityProduct = { product: this.product, subProduct: 'topic-activity/practice' };
          break;
        case 'Ga':
          this.activityProduct = {
            product: this.product,
            subProduct: this.product === Products.spelling ? 'game' : 'topic-activity/game',
          };
          break;
        case 'Le':
          this.activityProduct = { product: this.product, subProduct: 'topic-activity/lesson' };
          break;
        case 'Ws':
          this.activityProduct = { product: this.product, subProduct: 'word-sort' };
          break;
        case 'practice':
        case 'pretest':
        case 'test':
        case 'posttest':
          if (this.product === Products.spelling) {
            this.activityProduct = { product: this.product, subProduct: prefix };
          } else {
            this.activityProduct = { product: this.product, subProduct: 'topic-activity/challenge' };
          }
          break;
        case 'Fw':
          if (this.product === Products.handwriting) {
            // Free-write that launches into Digital-Resources means the student is looking at their portfolio.
            this.activityProduct = { product: this.product, subProduct: 'portfolio' };
          }
          break;
        default:
          break;
        }
      }
    }
  }

  /**
   * Determines if bootstrap using HEG rather than ZBPortal.
   */
  private isHeg(): boolean {
    return this.product === Products.ece;
  }

}
