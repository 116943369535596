import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AuthService } from '@core/auth.service';
import { BootstrapService } from '@core/bootstrap.service';
import { BrowserService } from '@core/browser.service';
import { PreferenceService } from '@core/preference.service';
import { ProductService } from '@core/product.service';
import { SfxService } from '@core/sfx.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Preloader } from '@shared/preloader';
import { RandomDataService } from '@shared/random-data.service';
import { SharedModule } from '@shared/shared/shared.module';
import * as storejs from 'store/dist/store.modern';
import * as localStorage from 'store/storages/localStorage';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { ResourceAccessDeniedComponent } from './resource-access-denied/resource-access-denied.component';
import { ResourceNotFoundComponent } from './resource-not-found/resource-not-found.component';
import { RootComponent } from './root/root.component';
import { metaReducers, APP_REDUCERS } from './store';

export function baseHrefFactory() {
  const pattern = /^\/sites\/([a-zA-Z0-9-_]+\/)/i;
  const match = window.location.pathname.match(pattern);
  if (match && match.length > 1) {
    return `/sites/${match[1]}`;
  }
  return '/';
}

/** Provides a factory for JWT interceptor configuration so that whitelist domains can be configured dynamically. */
export function jwtOptionsFactory() {
  return {
    tokenGetter: () => {
      const store = storejs.createStore([localStorage]);
      // if localhost testing, fetch the token from the query string so the request can be authenticated
      if (window.location.host.toLocaleLowerCase() === 'localhost:4300') {
        if (new URLSearchParams(window.location.search).get('token')) {
          store.set('coreApiToken', new URLSearchParams(window.location.search).get('token'));
        }
      }
      const token = store.get('coreApiToken');
      return (token) || null;
    },
    disallowedRoutes: [
      /^sites/,
    ],
    allowedDomains: [environment.apiUrl.replace('https://', '')],
  };
}

@NgModule({
  declarations: [
    AppComponent,
    RootComponent,
    ResourceNotFoundComponent,
    PlaceholderComponent,
    ResourceAccessDeniedComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'CSRF-Token',
      headerName: 'X-CSRF-Token',
    }),
    JwtModule.forRoot({ jwtOptionsProvider: { provide: JWT_OPTIONS, useFactory: jwtOptionsFactory } }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    StoreModule.forRoot(APP_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    EffectsModule.forRoot([]),
  ],
  providers: [
    { provide: APP_BASE_HREF, useFactory: baseHrefFactory }, // this overides base href=""
    AuthService,
    BootstrapService,
    BrowserService,
    RandomDataService,
    PreferenceService,
    Preloader,
    ProductService,
    SfxService,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule { }
